import { useTranslation } from 'react-i18next'

export const UniteInstructions = () => {
  const { t } = useTranslation()
  return (
    <div className='text-sm'>
      <div className='font-bold'>
        {t('unite_redeem_in_title', 'Pokémon UNITE Code Redemption Instructions')}
      </div>
      <ol className='list-decimal list-outside ml-4'>
        <li>{t('unite_instructions_to_gift_exchange', 'In Pokémon UNITE, go to the events screen, select Daily Events, and select Gift Exchange from the list on the left.')}</li>
        <li>{t('unite_select_exchange', 'Select the Exchange button.')}</li>
        <li>{t('unite_enter_gift_code', 'On the Gift Exchange screen, enter your gift code.')}</li>
        <li>{t('unite_gift_timline', 'Up to 48 hours after successfully entering your gift code, you\'ll receive in-game mail containing the item!')}</li>
     </ol>
     <div className='mt-8'>
      <div className='mt-2'>{t('unite_note_limit_of_one', 'Only one gift code can be redeemed per Pokémon UNITE account.')}</div>
      <div className='mt-2'>{t('unite_note_gift_expiration_timeline', 'Gifts expire 30 days after they appear in your mail, so please claim them as soon as possible.')}</div>
      <div className='mt-2'>{t('unite_note_gift_expired', 'Please note that expired gift codes cannot be redeemed.')}</div>
     </div>
    </div>
  )
}
