import { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../app'
import { requestInfoAboutMe } from '../utils/pokemonAuthApi'

import spinner from '../assets/images/pokeball_gray.png'
import { useQueryString } from '../utils/utils'
import { getTwitchAccount } from '../utils/api'

interface CallbackProps extends RouteComponentProps { }

const ptcsLogin = async (tokenUri: string, code: string, clientId: string, callbackUri: string, verifier: string) => {
  const body = `code=${code}&grant_type=authorization_code&client_id=${clientId}&code_verifier=${verifier}&redirect_uri=${callbackUri}`
  const response = await fetch(tokenUri, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body
  })

  if (!response.ok) {
    throw Error()
  }

  const responseJson = await response.json()
  return responseJson.access_token
}

export const Callback = (_props: CallbackProps) => {
  // eslint-disable-next-line
  const [userInfo, setUserInfo] = useContext(UserContext)
  const { t } = useTranslation()
  const queryString = useQueryString()
  const history = useHistory()

  let [message, setMessage] = useState('')
  message = t('authentication_loading_in_progress', 'Loading…')

  useEffect(() => {
    async function handleCallback () {
      const code = queryString.get('code')
      const state = queryString.get('state')
      const authError = t('authentication_error_completing_login', 'Error logging into your account')
      if (!code) {
        setMessage(authError)
        return
      }
      if (!state || state !== window.sessionStorage.getItem('pkce_state')) {
        setMessage(authError)
        return
      }

      const verifier = window.sessionStorage.getItem('pkce_verifier')
      const tokenUri = window.sessionStorage.getItem('pkce_token_uri')
      const redirectUri = window.sessionStorage.getItem('pkce_redirect_uri')
      const clientId = window.sessionStorage.getItem('pkce_client_id')

      if (!verifier || !tokenUri || !redirectUri || !clientId) {
        setMessage(authError)
        return
      }
      const accessToken = await ptcsLogin(tokenUri, String(code), clientId, redirectUri, verifier)
      window.sessionStorage.setItem('oauth_access_token', accessToken)

      const userData = await requestInfoAboutMe(accessToken)
      setUserInfo({
        loggedIn: true,
        ptcsUsername: userData.username,
        twitchUsername: '',
        tokenExpiration: new Date(userData.expirationTimestamp)
      })

      // attempt to get twitch username to see if the user has linked before
      // and has an valid access token associated with their account
      if (accessToken && accessToken !== '') {
        try {
          const getTwitchAccountResponse = await getTwitchAccount(accessToken)
          if (getTwitchAccountResponse.status === 200 && getTwitchAccountResponse.data !== '') {
            window.sessionStorage.setItem('twitch_username', getTwitchAccountResponse.data)
            setUserInfo({
              loggedIn: true,
              ptcsUsername: userData.username,
              twitchUsername: getTwitchAccountResponse.data,
              tokenExpiration: new Date(userData.expirationTimestamp)
            })
          }
        } catch (e) {
          console.log(e)
        }
      }

      window.sessionStorage.removeItem('pkce_verifier')
      window.sessionStorage.removeItem('pkce_token_uri')
      window.sessionStorage.removeItem('pkce_redirect_uri')
      window.sessionStorage.removeItem('pkce_client_id')
      history.push('/')
    }

    handleCallback()
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <img className='spinner' src={spinner} alt={t('pokemon_spinner', 'A spinner, used for loading')} />
      <p className='text-center pt-3 text-lg'>{message}</p>
    </div>
  )
}
