import React, { useState, useEffect, useContext } from 'react'
import { RouteComponentProps } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { NumberedPanel } from '../components/numbered_panel'
import { StreamPanel } from '../components/stream_panel'
import { inventoryUri, pokemonSignupUri } from '../utils/setup'

import logo from '../assets/images/pokemon_logo.png'
import twitchLogo from '../assets/images/twitch-logo.png'
import step3 from '../assets/images/step3.png'
import header from '../assets/images/header.png'

import pokemonGoProfileImage from '../assets/images/twitch_profile_images/twitch-pokemon-go.png'
import pokemonProfileImage from '../assets/images/twitch_profile_images/twitch-pokemon.jpg'
import pokemonUniteProfileImage from '../assets/images/twitch_profile_images/twitch-pokemon-unite.png'

import '../styles/base.css'
import '../styles/landing.css'
import { UserContext } from '../app'
import { callPokemonAuth } from '../utils/pokemonAuthApi'
import { startAuthCodeFlow } from '../utils/twitchApi'
import { linkTwitchAccountWithPTCS } from '../utils/api'
import { AxiosError } from 'axios'

interface LandingProps extends RouteComponentProps {}

function useQueryString () {
  return new URLSearchParams(useLocation().search)
}

export const Landing = (_props: LandingProps) => {
  const [userInfo, setUserInfo] = useContext(UserContext)
  const [twitchLinkError, setTwitchLinkError] = useState('')
  const { t } = useTranslation()
  const queryString = useQueryString()

  const loginText = t('authentication_log_in', 'Log In')
  const loggedInText = t('authentication_logged_in', 'Logged In')
  const twitchLinkingText = t('authentication_link_with_twitch', 'Link With Twitch')
  const twitchLinkInProgressText = t('authentication_twitch_link_in_progress', 'Linking in Progress')
  const twitchLinkedText = t('authentication_twitch_linked', 'Linked')
  const twitchLinkingRetryText = t('authentication_twitch_linking_retry', 'Retry Linking')
  const twitchConnectionStatusText = t('twitch_connected_status', 'You are connected as:')
  // eslint-disable-next-line
  const ptcsLoginStatusText = t('authentication_logged_in_as', 'Logged In as')
  const headerText = t('header_text', 'How Pokémon Drops Work')
  const headerSubtext = t('header_subtext', 'Twitch Drops are a fun way to get in-game items just for watching our streams on Twitch! All you need to do is log into your Pokémon Trainer Club and Twitch account, watch our streams, and meet the Drop goal.')
  const ptcsPanelText = t('ptcs_panel_text', 'Log In to Your Pokemon Trainer Club Account')
  const twitchPanelText = t('twitch_panel_text', 'Link to Your Twitch Account')
  const inventoryPanelText = t('inventory_panel_text', ' Watch Streams and Start Collecting')
  const inventoryPanelSubtext = t('inventory_panel_subtext', 'All of your claimed drops will be collected in your Drop Inventory')
  const ptcsSignupText1 = t('ptcs_signup_text1', 'If you don\'t have a Trainer Club account, ')
  const ptcsSignupText2 = t('ptcs_signup_text2', ' here.')
  const ptcsSignupActionText = t('ptcs_signup_action_text', 'sign up')
  const streamHeaderText = t('stream_header_text', 'Our Streams')
  const twitchLinkConflictError = t('twitch_already_linked_conflict', 'Woops. It looks like you have already linked another Twitch account')
  const twitchLinkGenericError = t('twitch_link_generic_error', 'There was an issue linking your Twitch account.')
  let twitchStatusText = twitchPanelText

  // eslint-disable-next-line
  let [stateObjects, setStateObjects] = useState({
    pokemonButtonText: loginText,
    twitchButtonText: twitchLinkingText,
    pokemonButtonStatus: 'primary',
    twitchButtonStatus: 'secondary',
    inventoryButtonStatus: 'secondary',
    pokemonButtonDisabled: false,
    twitchButtonDisabled: false,
    inventoryButtonDisabled: false
  })

  const twitchUsername = window.sessionStorage.getItem('twitch_username')
  const twitchAuthCode = window.sessionStorage.getItem('twitch_auth_code')
  if (twitchUsername) {
    userInfo.twitchUsername = twitchUsername
  }

  // figure out if we made it past the first stage
  const oauthToken = window.sessionStorage.getItem('oauth_access_token')

  // this is how we check if we've just recieved the twitch auth code, or if we're all done with the steps
  useEffect(() => {
    async function handleCallback () {
      // handles the twitch auth code callback and account linking
      if (!window.sessionStorage.getItem('twitch_username') && twitchAuthCode && oauthToken) {
        try {
          const twitchLinkResponse = await linkTwitchAccountWithPTCS(oauthToken, String(twitchAuthCode))
          window.sessionStorage.setItem('twitch_username', twitchLinkResponse.data.TwitchUsername)
          setUserInfo({
            loggedIn: userInfo.loggedIn,
            ptcsUsername: userInfo.ptcsUsername,
            twitchUsername: twitchLinkResponse.data.TwitchUsername,
            tokenExpiration: userInfo.tokenExpiration
          })
        } catch (error) {
          const axiosErr = (error as AxiosError)
          if (axiosErr && axiosErr.response) {
            switch (axiosErr.response.status) {
              case 409:
                setTwitchLinkError(twitchLinkConflictError)
                break
              case 424:
              case 500:
              default:
                console.error('error linking accounts')
                setTwitchLinkError(twitchLinkGenericError)
                break
            }
          }
        }
      }
    }
    handleCallback()
  // eslint-disable-next-line
  }, [])

  const language = queryString.get('lng')
  if (language) {
    window.sessionStorage.setItem('lng', language)
  }

  // assign the text and states for the buttons
  if (!oauthToken) {
    // starting state
    stateObjects = {
      pokemonButtonStatus: 'primary',
      pokemonButtonText: loginText,
      twitchButtonStatus: 'secondary',
      twitchButtonText: twitchLinkingText,
      inventoryButtonStatus: 'secondary',
      pokemonButtonDisabled: false,
      twitchButtonDisabled: true,
      inventoryButtonDisabled: true
    }
  } else if (twitchUsername || twitchAuthCode) {
    // signed in with both twitch and ptcs
    if (twitchUsername) {
      // we know their username and the link is good
      stateObjects = {
        pokemonButtonStatus: 'secondary',
        pokemonButtonText: loggedInText,
        twitchButtonStatus: 'secondary',
        twitchButtonText: twitchLinkedText,
        inventoryButtonStatus: 'primary',
        pokemonButtonDisabled: true,
        twitchButtonDisabled: true,
        inventoryButtonDisabled: false
      }
      if (userInfo.twitchUsername !== null && userInfo.twitchUsername !== undefined && userInfo.twitchUsername !== '') {
        twitchStatusText = `${twitchConnectionStatusText} ${userInfo.twitchUsername}`
      }
    } else if (twitchLinkError !== '') {
      stateObjects = {
        pokemonButtonStatus: 'secondary',
        pokemonButtonText: loggedInText,
        twitchButtonStatus: 'primary',
        twitchButtonText: twitchLinkingRetryText,
        inventoryButtonStatus: 'primary',
        pokemonButtonDisabled: true,
        twitchButtonDisabled: false,
        inventoryButtonDisabled: false
      }
    } else {
      // we don't yet know the username or whether the link is good
      stateObjects = {
        pokemonButtonStatus: 'secondary',
        pokemonButtonText: loggedInText,
        twitchButtonStatus: 'secondary',
        twitchButtonText: twitchLinkInProgressText,
        inventoryButtonStatus: 'primary',
        pokemonButtonDisabled: true,
        twitchButtonDisabled: true,
        inventoryButtonDisabled: false
      }
    }
  } else {
    // only signed in with ptcs, still to sign in with twitch
    stateObjects = {
      pokemonButtonStatus: 'secondary',
      pokemonButtonText: loggedInText,
      twitchButtonStatus: 'primary',
      twitchButtonText: twitchLinkingText,
      inventoryButtonStatus: 'secondary',
      pokemonButtonDisabled: true,
      twitchButtonDisabled: false,
      inventoryButtonDisabled: true
    }
    if (userInfo.ptcsUsername !== undefined && userInfo.ptcsUsername !== '') {
      stateObjects.pokemonButtonText = 'Logged in'
    }
  }

  function navigateTo (url: string) {
    window.location.href = url
  }

  return (
    <div className='body-container'>
      <img className='header-image' src={header} alt="twitch header with pikachu"/>
      <div className='base-container-border main-area'>
        <div className='header header-area'>
          <h1 className='text-3xl font-bold'>{headerText}</h1>
          <div className='text-sm font-medium tracking-wide'>{headerSubtext}</div>
        </div>
        <div className='grid-container w-5/6'>
          <div className='flex flex-row flex-wrap'>
            <NumberedPanel number={1}>
              <img src={logo} className='panel-image' alt="pokemon logo"/><br/>
              <div className='text-lg/5 font-black tracking-wide'>{ptcsPanelText}</div>
              {!userInfo.loggedIn
                ? <div className='text-sm/[18px] font-medium panel-subtext'>{ptcsSignupText1} <a href={pokemonSignupUri} className='underline link-color hover:link-color'>{ptcsSignupActionText}</a> {ptcsSignupText2}</div>
                : <div className='text-sm/[18px] font-medium panel-subtext'>{ptcsLoginStatusText}<br/>{userInfo.ptcsUsername}</div>
              }
              <BasicButton className={'panel-button'} type={'button'} buttonStatus={stateObjects.pokemonButtonStatus} onClick={() => callPokemonAuth()} text={stateObjects.pokemonButtonText} disabled={stateObjects.pokemonButtonDisabled}/>
            </NumberedPanel>
            <NumberedPanel number={2}>
              <img src={twitchLogo} className='panel-image' alt="twitch logo"/><br/>
              <div className='text-lg/5 font-black tracking-wide'>{twitchStatusText}</div>
              <div className='text-sm/[18px] font-medium panel-subtext text-red-700'>{twitchLinkError}</div>
              <BasicButton className={'panel-button'} type={'button'} buttonStatus={stateObjects.twitchButtonStatus} onClick={() => startAuthCodeFlow()} text={stateObjects.twitchButtonText} disabled={stateObjects.twitchButtonDisabled}/>
            </NumberedPanel>
            <NumberedPanel number={3}>
              <img src={step3} className='panel-image' alt="inventory header"/><br/>
              <div className='text-lg/5 font-black tracking-wide'>{inventoryPanelText}</div>
              <div className='text-sm/[18px] font-medium panel-subtext'>{inventoryPanelSubtext}</div>
              <BasicButton className={'panel-button'} type={'button'} buttonStatus={stateObjects.inventoryButtonStatus} onClick={() => navigateTo(inventoryUri)} text={t('view_inventory', 'View Inventory')} disabled={stateObjects.inventoryButtonDisabled}/>
            </NumberedPanel>
          </div>
        </div>
      </div>
      <div className='base-container-border streams-container-border main-area'>
        <div className='header header-area'>
          <h1 className='text-3xl font-bold'>{streamHeaderText}</h1>
        </div>
        <div className='grid-container w-2/3'>
          <div className='flex flex-row flex-wrap'>
            <StreamPanel>
              <div
                role='link'
                tabIndex={0}
                className='stream-link'
                onKeyPress={() => window.open('https://www.twitch.tv/pokemontcg', '_blank')}
                onClick={() => window.open('https://www.twitch.tv/pokemontcg', '_blank')}
              >
              <img src={pokemonProfileImage} className='profile-pic' alt="pokemon tcg twitch avatar"/><br/>
              <div className='text-lg/5 font-medium'>{t('pokemon_tcg', 'Pokémon TCG')}</div>
              </div>
            </StreamPanel>
            <StreamPanel>
              <div
                role='link'
                tabIndex={0}
                className='stream-link'
                onKeyPress={() => window.open('https://www.twitch.tv/pokemongo', '_blank')}
                onClick={() => window.open('https://www.twitch.tv/pokemongo', '_blank')}
              >
              <img src={pokemonGoProfileImage} className='profile-pic' alt="pokemon go twitch avatar"/><br/>
              <div className='text-lg/5 font-medium'>{t('pokemon_go', 'Pokémon GO')}</div>
              </div>
            </StreamPanel>
            <StreamPanel>
              <div
                role='link'
                tabIndex={0}
                className='stream-link'
                onKeyPress={() => window.open('https://www.twitch.tv/pokemon', '_blank')}
                onClick={() => window.open('https://www.twitch.tv/pokemon', '_blank')}
              >
              <img src={pokemonProfileImage} className='profile-pic' alt="pokemon twitch avatar"/><br/>
              <div className='text-lg/5 font-medium'>{t('pokemon', 'Pokémon')}</div>
              </div>
            </StreamPanel>
            <StreamPanel>
              <div
                role='link'
                tabIndex={0}
                className='stream-link'
                onKeyPress={() => window.open('https://www.twitch.tv/PokemonUnite', '_blank')}
                onClick={() => window.open('https://www.twitch.tv/PokemonUnite', '_blank')}
              >
              <img src={pokemonUniteProfileImage} className='profile-pic' alt="pokemon unite twitch avatar"/><br/>
              <div className='text-lg/5 font-medium'>{t('pokemon_unite', 'Pokémon UNITE')}</div>
              </div>
            </StreamPanel>
          </div>
        </div>
      </div>
    </div>
  )
}
