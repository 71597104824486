import React from 'react'
import './numbered-panel.css'
import './panel.css'

interface StreamPanelProps {
  children?: React.ReactNode;
}

export function StreamPanel (_props: StreamPanelProps) {
  return (
    <div className='panel grow'>
      <div className='text-center h-full pb-6 px-6'>
        <div style={{ marginTop: '25px', height: '100%' }}>
          {_props.children}
        </div>
      </div>
    </div>
  )
}
