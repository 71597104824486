import Axios from 'axios'
import cryptoBrowserify from 'crypto-browserify'

import { baseUri, twitchClientId, twitchTokenUri } from './setup'

export const twitchAuthApi = Axios.create({
  baseURL: twitchTokenUri,
  timeout: 15000 // millis
})

export const validateTwitchToken = async (accessToken: string) => {
  const response = await twitchAuthApi.get('/validate', {
    headers: {
      Authorization: `OAuth ${accessToken}`
    }
  })

  const tokenExpiresIn = response.data.expires_in
  if (tokenExpiresIn > 0) {
    return true
  } else {
    window.sessionStorage.removeItem('twitch_access_token')
    return false
  }
}

export const startAuthCodeFlow = () => {
  const base64URLEncode = (str: { toString: (arg0: string) => string }) => {
    return str.toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '')
  }

  const twitchState = base64URLEncode(cryptoBrowserify.randomBytes(16))
  window.sessionStorage.setItem('twitch_state', twitchState)
  const twitchRedirectUrl = `${baseUri}/twitch-callback`
  const twitchAuthUrl = `${twitchTokenUri}/authorize?response_type=code&client_id=${twitchClientId}&redirect_uri=${twitchRedirectUrl}&scope=&state=${twitchState}`

  window.location.href = twitchAuthUrl
}
