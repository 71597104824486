import React from 'react'
import { useTranslation } from 'react-i18next'

import logo from './assets/images/pokemon_logo.png'

export function Header () {
  const { t } = useTranslation()
  return (
    <div>
      <header>
        <meta name='viewport' content='width=device-width, initial-scale=1.0' />
        <div className='logo-wrapper'>
          <img className='logo' src={logo} alt={t('pokemon_logo', 'The Pokémon logo')} />
        </div>
      </header>
    </div>
  )
}

interface AppWrapperProps {
  children: React.ReactNode
}

export function AppWrapper ({ children }: AppWrapperProps) {
  return (
    <div className='App'>
      <div className='body-container'>
        <div className='body-wrapper'>
          { children }
        </div>
      </div>
    </div>
  )
}
