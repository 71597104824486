import '../../styles/base.css'
import './modal.css'
import './leaving_site_modal.css'
import wavingPikachu from './../../assets/images/pikachu.svg'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

interface TermsOfUseModalProps {
  setModal: Function
  modalRef: React.RefObject<HTMLDivElement>
}

function TermsOfUseModal (props: TermsOfUseModalProps) {
  const { t } = useTranslation()
  const eatClick = (e: any) => {
    e.stopPropagation()
  }

  useEffect(() => {
    // force into view
    props.modalRef?.current?.scrollIntoView({ block: 'start' })
    props.modalRef.current?.focus()
    // eslint-disable-next-line
  }, [props.modalRef])

  return (
    <div id='leaving-site-modal' className='modal' onClick={eatClick} role="presentation">
      <div className='graphic'>
        <img className='w-3/4' src={wavingPikachu} alt='waving pikachu' />
      </div>
      <div className='content'>
        <div className='big-words'>{t('rewards_terms_of_use_header', 'Rewards Program Terms of Use')}</div>
        <div className='small-words'>
          {t('terms_of_use_text_one', 'Pokemon Twitch Rewards (“Program”) are offered at the sole discretion of the Pokemon Company International (“Sponsor,” “we,” “us,” “our”).  The current Program begins on June 30, 2023 and continues until such time as the Sponsor terminates the Program at its discretion (“Program Period”). During the Program Period the specific activities, and rewards may change at Sponsor’s discretion. Sponsor may terminate this Program at any time without prior notice in its sole and absolute discretion. This program is open to only legal residents of the fifty (50) United States and the District of Columbia who are the age of majority in their jurisdiction at the time of participation. This Program is void where prohibited or restricted by law. All federal state and local laws apply.')}
          <br />
          <br />
          {t('terms_of_use_text_two', 'Eligible Participants may receive rewards by watching and interacting with Twitch Streams.  Participants must watch the stream for the prescribed amount of time and/or must follow any and all instructions provided in the stream to receive the prize, failure to do so will not yield a Prize.   Prizes are not redeemable for cash, transferable or assignable for any reason, and are not transferable upon death, as part of a domestic relations matter or otherwise by operation of law. The sale or transfer of Prizes is strictly prohibited. Prizes may not be sold on any secondary market, and any transfer of Prizes to a secondary market shall be deemed void. Any Prizes remaining in a Participant’s Account if the Program is canceled will be forfeited without compensation. There shall be no carry over or transfer of Punches to other programs, unless otherwise determined by us in our sole and absolute discretion. Prizes may expire, at which point they will be inaccessible by participants. Prizes are offered for a limited time, while supplies last, and provided on a “first come, first serve” basis in relation to when Participants redeem. Once supplies of a Prize offered are exhausted, the Prize will expire and will no longer be available for redemption in the Program. Sponsor reserves the right, at its sole discretion (without obligation) to substitute Prize of greater or equal value. No exchanges, returns or refunds on Prizes are permitted for any reason. Sponsor does not guarantee the availability of any Prize for any period during the Program.')}
      </div>
      <div className='buttons'>
          <div
            className='button'
            onClick={() => props.setModal(undefined)}
            onKeyPress={() => props.setModal(undefined)}
            role='button'
            tabIndex={0}
          >
            {t('close', 'Close')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsOfUseModal
