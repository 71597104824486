import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import spinner from '../assets/images/pokeball_gray.png'
import { useQueryString } from '../utils/utils'

interface CallbackProps extends RouteComponentProps {}

export const TwitchCallback = (_props: CallbackProps) => {
  const { t } = useTranslation()
  const queryString = useQueryString()
  const history = useHistory()

  let [message, setMessage] = useState('')
  message = t('authentication_loading_in_progress', 'Loading…')

  useEffect(() => {
    async function handleCallback () {
      const authCode = queryString.get('code')
      const state = queryString.get('state')
      const authError = t('authentication_error_completing_login', 'Error logging into your account')
      try {
        if (!state || state !== window.sessionStorage.getItem('twitch_state')) {
          setMessage(authError)
          return
        }
        if (!authCode) {
          setMessage(authError)
        } else {
          window.sessionStorage.setItem('twitch_auth_code', authCode)
          history.push('/')
        }
      } catch (error) {
        setMessage(authError)
      }
    }

    handleCallback()
  }, [t, queryString, history])

  return (
    <div>
      <img className='spinner' src={spinner} alt={t('pokemon_spinner', 'A spinner, used for loading')} />
      <p className='text-center pt-3 text-lg'>{message}</p>
    </div>
  )
}
