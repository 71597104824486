import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../app'
import { callPokemonAuth } from '../utils/pokemonAuthApi'
export function Header () {
  // eslint-disable-next-line
  const [userInfo, setUserInfo] = useContext(UserContext)
  const { t } = useTranslation()

  function loginWithPokemon () {
    callPokemonAuth()
  }

  function logoutOfPokemon () {
    // we just clear our storage of info and reload the page
    window.sessionStorage.clear()
    window.location.reload()
  }

  return (
    <div className='w-full h-[30px] z-50 text-white bg-black text-xs'>
      <div className='absolute top-2 right-5 inline-block'>
        {userInfo.ptcsUsername !== ''
          ? <div className='font-bold inline-block pr-2'>{userInfo.ptcsUsername}</div>
          : null
        }
        {userInfo.loggedIn
          ? <button onClick={() => logoutOfPokemon()}>{t('log_out', 'Log Out')}</button>
          : <button onClick={() => loginWithPokemon()}>{t('log_in', 'Log In')}</button>
        }
      </div>
    </div>
  )
}
