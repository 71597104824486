import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import rotom from '../assets/images/rotom.png'
import { markRewardAsViewed } from '../utils/api'
import { ModalContext } from '@pokemon/design.ui.containers.base-modal'
import { InventoryItemModel } from '../models/inventoryItemModel'
import { formatDate } from '../utils/utils'
import '../styles/inventory_item.css'

interface InventoryItemProps {
  inventoryItem: InventoryItemModel;
  setInventoryItem: Function;
  expirationDate?: Date;
  children?: React.ReactNode;
}

enum ItemType {
  Normal,
  ExpiringSoon,
  New,
  Expired
}

export function InventoryItem (_props: InventoryItemProps) {
  const { t } = useTranslation()
  const { openModal } = useContext(ModalContext)

  const itemBadgeNewText = t('item_badge_new', 'NEW')
  const itemBadgeEndingSoonText = t('item_badge_endingsoon', 'ENDING SOON')
  const showCodeText = t('show_code_text', 'Show Code')
  const expiredText = t('expired', 'Expired')
  const hasExpiredText = t('this_code_has_expired', 'This code has expired.')
  const expiresInText = t('expires', 'Expires in')
  const expiresText = t('expires', 'Expires')
  const noExpirationText = t('no_expiration', 'No Expiration')

  // eslint-disable-next-line
  const [hideButton, setHideButton] = useState(false)
  const [itemType, setItemType] = useState(ItemType.Normal)
  const [itemClasses, setItemClasses] = useState('border-color-default-grey')
  const [badgeClasses, setBadgeClasses] = useState('hidden')
  const [itemBadgeText, setItemBadgeText] = useState('')
  const [expirationPreText, setExpirationPreText] = useState('')
  const [expirationPostText, setExpirationPostText] = useState('')
  const [showCodeButton, setShowCodeButton] = useState(<></>)
  const [expirationRibbon, setExpirationRibbon] = useState(<div className='w-[206px] h-10 relative mt-5'></div>)
  const [fontColorOverride, setFontColorOverride] = useState('')

  function ShowCode (rewardId: string) {
    if (!_props.inventoryItem.viewed) {
      markRewardAsViewed(rewardId)
      setItemType(ItemType.Normal)
    }
    _props.setInventoryItem(_props.inventoryItem)
    openModal('inventoryItemModal')
  }

  useEffect(() => {
    const isExpirationMinValue = new Date(_props.inventoryItem.expiration).getTime() === new Date('0001-01-01T00:00:00Z').getTime()
    const expiringSoonCutoff = new Date()
    expiringSoonCutoff.setDate(expiringSoonCutoff.getDate() + 7)
    const didExpire = _props.inventoryItem.expiration && (new Date().getTime() > new Date(_props.inventoryItem.expiration).getTime())
    if (!isExpirationMinValue && didExpire) {
      setItemType(ItemType.Expired)
    } else if (!isExpirationMinValue && _props.inventoryItem.expiration && new Date(_props.inventoryItem.expiration).getTime() < expiringSoonCutoff.getTime()) {
      setItemType(ItemType.ExpiringSoon)
    } else if (!_props.inventoryItem.viewed) {
      setItemType(ItemType.New)
    }
    // eslint-disable-next-line
  }, [_props.inventoryItem])

  useEffect(() => {
    const isExpirationMinValue = new Date(_props.inventoryItem.expiration).getTime() === new Date('0001-01-01T00:00:00Z').getTime()
    let button = <BasicButton className='mx-auto mb-7 w-40' type={'button'} buttonStatus={'primary'} text={showCodeText} onClick={() => ShowCode(_props.inventoryItem.id)} />
    let expireInfoPreText = ''
    let expireInfoPostText = ''
    const duration = new Date(_props.inventoryItem.expiration).getTime() - Date.now()
    const durationInDays = Math.floor(duration / (1000 * 3600 * 24))
    if (durationInDays < 2) {
      expireInfoPreText = expiresText
      expireInfoPostText = ` ${t('today', 'Today')}`
    } else if (durationInDays < (365 * 2)) {
      expireInfoPreText = expiresInText
      expireInfoPostText = ` ${durationInDays} ${t('days', 'days')}`
    } else {
      const durationInYears = Math.floor(durationInDays / 365)
      expireInfoPreText = expiresInText
      expireInfoPostText = ` ${durationInYears} ${t('years', 'years')}`
    }
    switch (itemType) {
      case ItemType.ExpiringSoon:
        setItemBadgeText(itemBadgeEndingSoonText)
        setItemClasses('border-ending-soon-red')
        setBadgeClasses('bg-ending-soon-red block absolute text-white -ml-px -mt-px pt-0.5 w-24 h-5')
        setExpirationPreText(expireInfoPreText)
        setExpirationPostText(expireInfoPostText)
        break
      case ItemType.Expired:
        setItemClasses('expired')
        button = <BasicButton className='mx-auto mb-7 w-40 disabled' type={'button'} buttonStatus={'disabled'} text={expiredText} disabled/>
        setExpirationPreText(hasExpiredText)
        setExpirationPostText('')
        setFontColorOverride('text-grey-expired')
        break
      case ItemType.New:
        setItemBadgeText(itemBadgeNewText)
        setItemClasses('border-new-green')
        setBadgeClasses('bg-new-green block absolute text-white -ml-px -mt-px pt-0.5 w-9 h-5')
        if (!isExpirationMinValue) {
          setExpirationPreText(expireInfoPreText)
          setExpirationPostText(expireInfoPostText)
        } else {
          setExpirationPreText(noExpirationText)
          setExpirationPostText('')
        }
        break
      default:
        setItemBadgeText('')
        setItemClasses('border-color-default-grey')
        setBadgeClasses('hidden')
        if (!isExpirationMinValue) {
          setExpirationPreText(expireInfoPreText)
          setExpirationPostText(expireInfoPostText)
        } else {
          setExpirationPreText(noExpirationText)
          setExpirationPostText('')
        }
        break
    }
    setShowCodeButton(button)

    // eslint-disable-next-line
  }, [itemType])

  useEffect(() => {
    if (expirationPreText !== '') {
      setExpirationRibbon(
        <div className='w-[206px] h-10 relative mt-5 bg-grey-f0'>
          <div className='text-left text-xs h-7 leading-none p-4 mx-auto'>
            <div className='inline'>{expirationPreText}</div><div className='font-bold inline'>{expirationPostText}</div>
          </div>
        </div>
      )
    }
  }, [expirationPreText, expirationPostText])

  return (
    <div className={`inventory-item flex-none basis-52 w-52 h-96 border border-solid rounded-2xl ${itemClasses}`} >
      <div className={`inventory-badge text-xs align-middle rounded-br-lg ${badgeClasses}`}>
        {itemBadgeText}
      </div>
      <div className='w-[206px] h-32 object-contain mt-5'>
        <img className='w-52 h-32 object-contain mt-5' src={_props.inventoryItem.rewardImageUrl !== '' ? `${_props.inventoryItem.rewardImageUrl}` : rotom } alt={_props.inventoryItem.rewardName}/>
      </div>
      {expirationRibbon}
      <div className='w-[206px] h-40 relative bottom-0 p-4'>
        <div className={`w-40 mx-auto ${fontColorOverride}`}>
          <div className='text-left text-xs h-7 leading-none'>{formatDate(_props.inventoryItem.grantedDate)}</div>
          <div className='text-left text-sm font-black h-8 leading-none'>{_props.inventoryItem.rewardName}</div>
          <div className='text-left text-xs h-7 leading-none'>{_props.inventoryItem.gameName}</div>
        </div>
        {!hideButton ? showCodeButton : null
        }
      </div>
    </div>
  )
}
