const stage = process.env.REACT_APP_STAGE
const baseUri = process.env.REACT_APP_DOMAIN
const twitchClientId = process.env.REACT_APP_TWITCH_CLIENT_ID
const apiBaseUri = process.env.REACT_APP_PTCS_REWARDS_API_DOMAIN
const pokemonOauthProviderUri = process.env.REACT_APP_PTCS_OAUTH_DOMAIN
const pokemonClientId = process.env.REACT_APP_PTCS_OAUTH_CLIENT_ID
const pokemonRedirectUri = `${baseUri}/callback`
const tokenUri = `${pokemonOauthProviderUri}/oauth2/token`
const inventoryUri = `${baseUri}/inventory`
const pokemonSignupUri = (stage === 'live' || stage === undefined) ? 'https://club.pokemon.com/us/pokemon-trainer-club/sign-up/' : `https://club.${stage}.pokemon.com/us/pokemon-trainer-club/sign-up/`
const pokemonInfoUri = (stage === 'live') ? 'https://me.pokemon.com' : `https://me.${stage}.pokemon.com`
const twitchTokenUri = 'https://id.twitch.tv/oauth2'
export {
  baseUri,
  twitchClientId,
  apiBaseUri,
  pokemonOauthProviderUri,
  pokemonClientId,
  pokemonRedirectUri,
  tokenUri,
  inventoryUri,
  pokemonSignupUri,
  pokemonInfoUri,
  twitchTokenUri
}
