import { useTranslation } from 'react-i18next'

export const GoInstructions = () => {
  const { t } = useTranslation()
  return (
    <div className='text-sm'>

      <div className='font-bold mt-5'>
        {t('redeem_on_web', 'To redeem an offer code on the web:')}
      </div>

      <ol className='list-decimal list-outside ml-4'>
        <li><a href="https://rewards.nianticlabs.com/app/pokemongo/signin" className='font-bold text-link'> {t('visit_web_here', 'Visit this website.')}</a></li>
        <li>{t('login_to_go', 'Log in using the same credentials you use to access your Pokémon Go account.')}</li>
        <li>{t('enter_codes', 'Enter your offer code')}</li>
        <li>{t('go_rewards_appear', 'After successfully redeeming an offer code, a message will display the items added to your inventory.')}</li>
      </ol>

      <div className='font-bold mt-5'>
        {t('go_redeem_for_ptc', 'To redeem an offer code on the web with Pokémon Trainer Club (PTC) accounts:')}
      </div>

      <div>
        {t('go_ptc_redemption_instructions_1', 'Offer code redemption on the web is currently unavailable for users with PTC accounts. If you log in using PTC, you can ')}
        <a href="https://niantic.helpshift.com/hc/en/6-pokemon-go/faq/86-linking-an-additional-login-provider-to-your-account/?s=account-sign-in&f=linking-an-additional-login-provider-to-your-account" className='font-bold text-link'>{t('go_ptc_redemption_url_text', 'link another login provider')}</a>
        {t('go_ptc_redemption_instructions_2', ' to your account and login via that account to redeem your offer code.')}
      </div>
    </div>
  )
}
