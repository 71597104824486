import React from 'react'
import './numbered-panel.css'
import circle from '../assets/images/circle.png'

interface NumberedPanelProps {
  number: number;
  children?: React.ReactNode;
}

export function NumberedPanel (_props: NumberedPanelProps) {
  return (
    <div className='numberpanel'>
      <div className='numberpanel-numberwrapper'>
        <img src={ circle } className='numberpanel-circle' alt=""/>
        <div className='numberpanel-number text-2xl'>{_props.number}</div>
      </div>
      <div className='numberpanel-details text-center'>
        <div style={{ marginTop: '25px', height: '100%' }}>
          {_props.children}
        </div>
      </div>
    </div>
  )
}
