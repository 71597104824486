import { BaseModal, ModalContext, ModalContextProps } from '@pokemon/design.ui.containers.base-modal'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InventoryItemModel } from '../../models/inventoryItemModel'
import { SelectedInventoryItemContext } from '../../pages/inventory'
import { GameType, RedemptionInstructions } from '../redemption-instructons/redemptionInstructions'

import '../../styles/inventory_item.css'
import copyIcon from '../../assets/images/copy-solid.png'
import { formatDateAndTime } from '../../utils/utils'
import i18n from '../../utils/i18n'

const latamCountryCodes = [
  'ar', // argentina
  'bo', // bolivia
  'cl', // chile
  'co', // colombia
  'cr', // costa rica
  'do', // dominican republic
  'ec', // ecuador
  'sv', // el salvador
  'gt', // guatemala
  'hn', // honduras
  'mx', // mexico
  'ni', // nicaragua
  'pa', // panama
  'py', // paraguay
  'pe', // peru
  'uy', // uruguay
  've' // venezuela
]

export const InventoryItemModal = () => {
  const { t } = useTranslation()
  const expiresInText = t('expires', 'Expires in')
  const expiresText = t('expires', 'Expires')

  const { closeModal } = useContext<ModalContextProps>(ModalContext)
  const inventoryItem = useContext<InventoryItemModel>(SelectedInventoryItemContext)
  const [copyFeedbackHidden, setCopyFeedbackHidden] = useState(true)
  const [imagePath, setImagePath] = useState('')

  function CopyToClipboard () {
    navigator.clipboard.writeText(inventoryItem.code)
    setCopyFeedbackHidden(false)
  }

  let gameType = GameType.None
  switch (inventoryItem.gameName) {
    case 'Pokémon GO':
      gameType = GameType.Go
      break
    // case 'Pokemon Center':
    //   gameType = Games.PokemonCenter
    //   break
    case 'Pokémon Trading Card Game Live':
    case 'Pokémon Trading Card Game':
      gameType = GameType.TCGL
      break
    case 'Pokémon UNITE':
      gameType = GameType.Unite
      break
    default:
      gameType = GameType.None
      break
  }

  const isExpirationMinValue = new Date(inventoryItem.expiration).getTime() === new Date('0001-01-01T00:00:00Z').getTime()
  let expireInfoPreText = ''
  let expireInfoPostText = ''
  const duration = new Date(inventoryItem.expiration).getTime() - Date.now()
  const durationInDays = Math.floor(duration / (1000 * 3600 * 24))
  if (durationInDays < 2) {
    expireInfoPreText = expiresText
    expireInfoPostText = ` ${t('today', 'Today')}`
  } else if (durationInDays < (365 * 2)) {
    expireInfoPreText = expiresInText
    expireInfoPostText = ` ${durationInDays} ${t('days', 'days')}`
  } else {
    const durationInYears = Math.floor(durationInDays / 365)
    expireInfoPreText = expiresInText
    expireInfoPostText = ` ${durationInYears} ${t('years', 'years')}`
  }

  const region = window.sessionStorage.getItem('region')

  const currentRegion = region?.toLowerCase()
  const currentLanguage = i18n.language.split('-')[0]
  const baseImagePath = `/TwitchDropsRatings/${GameType[gameType]}/rating`

  const checkImage = (src: string): Promise<boolean> => {
    return new Promise((resolve) => {
      const img = new Image()
      img.src = src
      img.onload = () => resolve(true)
      img.onerror = () => resolve(false)
    })
  }

  useEffect(() => {
    (async () => {
      if (currentRegion && latamCountryCodes.includes(currentRegion)) {
        setImagePath(`${baseImagePath}-es-xl.png`)
      } else if (currentRegion === 'br') {
        setImagePath(`${baseImagePath}-pt-br.png`)
      } else if (currentLanguage && currentRegion) {
        const specificImagePath = `${baseImagePath}-${currentLanguage}-${currentRegion}.png`

        const exists = await checkImage(specificImagePath)
        // In case where we dont have an image for certain regions, fall back to this image. en-us.png
        setImagePath(exists ? specificImagePath : `${baseImagePath}-en-us.png`)
      }
    })()
  }, [baseImagePath, currentLanguage, currentRegion])

  return (
    <BaseModal
      name="inventoryItemModal"
      aria-describedby="rewardName"
      contentBoxProps={{
        onClose: () => {
          closeModal('inventoryItemModal')
        },
        Logo: null,
        className: 'flex flex-wrap flex-row overflow-auto'
      }}
      sx={{
        '@media screen and (min-width: 1000px)': {
          ' .base-container': {
            width: '1000px !important',
            padding: '0px !important'
          }
        },
        '@media screen and (min-width: 900px)': {
          ' .base-container': {
            height: '560px !important',
            padding: '0px !important'
          }
        },
        '@media screen and (max-width: 1000px)': {
          ' .base-container': {
            width: '100% !important'
          }
        },
        '@media screen and (max-width: 600px)': {
          ' .base-container': {
            height: '100% !important'
          }
        }
      }}
      title="Inventory Item Info">
      <button className='absolute top-4 right-4' onClick={() => { closeModal('inventoryItemModal') }}>
        <img src="https://static.pokemon.com/images/times_solid.svg" alt="close_button" />
      </button>
      <div className='basis-full sm:basis-1/2 border-r-1 flex justify-center items-center h-[560px]'>
        <div className='w-5/6 h-5/6 relative'>
          <img className='w-full h-full object-scale-down' src={`${inventoryItem.rewardImageUrl}`} alt={inventoryItem.rewardName} />
          <img className='legal-image object-scale-down absolute bottom-0 right-0' src={imagePath} alt={`${gameType}-${currentLanguage}-${currentRegion}`} />
        </div>
      </div>
      <div className='basis-full md:basis-1/2'>
        <div className='md:h-[484px] lg:h-[484px] border-b-1 p-6 bg-grey-247 rounded-tr-[15px]'>
          <div className='text-xs'>{formatDateAndTime(inventoryItem.grantedDate)}</div>
          <div className='text-2xl font-black' id="rewardName">{inventoryItem.rewardName}</div>
          <div className='text-sm'>{inventoryItem.gameName}</div>
          {!isExpirationMinValue
            ? <div className='text-xs pt-1'>
              <div className='inline'>{expireInfoPreText}</div><div className='font-bold inline'>{expireInfoPostText}</div>
            </div>
            : null}
          <div className='mt-5 overflow-auto md:h-[350px] lg:h-[350px]'>
            <RedemptionInstructions gameType={gameType} />
          </div>
        </div>
        <div className='relative h-9 w-4/5 p-1 mt-[20px] mx-auto border border-solid border-new-green rounded text-sm text-left'>
          <div className='absolute top-1.5 left-1.5'>
            {inventoryItem.code}
          </div>
          <button className='absolute top-1.5 right-1.5' onClick={() => CopyToClipboard()}>
            <img src={copyIcon} alt={t('copy_icon', 'a clipboard icon to indicate copying functionality')} />
          </button>
          <div className='absolute -bottom-5 text-xs' hidden={copyFeedbackHidden}>
            copied to clipboard
          </div>
        </div>
      </div>
    </BaseModal>
  )
}
