import { useState, useEffect, useContext, createContext } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import { RouteComponentProps } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InventoryItem } from '../components/inventory_item'
import { baseUri } from '../utils/setup'
import { requestInventoryItems } from '../utils/api'
import { InventoryItemModel } from '../models/inventoryItemModel'

import header from '../assets/images/header.png'
import toolboxIcon from '../assets/images/toolbox-solid.svg'

import '../styles/base.css'
import backArrow from '../assets/images/arrow-left-solid.svg'
import { InventoryItemModal } from '../components/modals/inventoryItemModal'
import { ModalContextProvider } from '@pokemon/design.ui.containers.base-modal'
import { UserContext } from '../app'

interface InventoryProps extends RouteComponentProps {}

function useQueryString () {
  return new URLSearchParams(useLocation().search)
}

export const SelectedInventoryItemContext = createContext({} as InventoryItemModel)

export const Inventory = (_props: InventoryProps) => {
  // eslint-disable-next-line
  const [userInfo, setUserInfo] = useContext(UserContext)
  if (!userInfo.loggedIn && baseUri) {
    // we redirect them back to the home page to login
    window.location.href = baseUri
  }
  const { t } = useTranslation()
  const queryString = useQueryString()

  const inventoryHeaderText = t('inventory_header_text', 'Your Inventory')
  const sortByText = t('sort_by', 'Sort By')
  const mostRecentText = t('most_recent', 'Most Recent')
  const loadingMessage = t('authentication_loading_in_progress', 'Loading…')
  const getInventoryErrorMessage = t('get_inventory_error', 'There was a problem retrieving your inventory')
  const noItemsMessage = t('no_items', 'There are no items in your inventory yet. Stay tuned for upcoming Drop campaigns.')
  const [selectedInventoryItem, setSelectedInventoryItem] = useState<InventoryItemModel>({
    id: '',
    code: '',
    gameName: '',
    rewardName: '',
    rewardImageUrl: '',
    fulfillmentStatus: '',
    grantedDate: new Date(),
    viewed: false,
    expiration: new Date()
  })
  const [inventory, setInventory] = useState<InventoryItemModel[]>([])
  const [isInitialized, setIsInitialized] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const trainerUsername = window.sessionStorage.getItem('ptcs_user') || ''

  if (queryString.get('lng')) {
    window.sessionStorage.setItem('lng', String(queryString.get('lng')))
  }

  const sortedDescendingDateInventory = inventory.sort((a, b) => {
    // since the grantedDate is currently a string, we need to convert it to a date and then compare
    return new Date(b.grantedDate).getTime() - new Date(a.grantedDate).getTime()
  })

  useEffect(() => {
    if (!isInitialized) {
      async function handleCallback () {
        const oauthToken = window.sessionStorage.getItem('oauth_access_token')
        if (oauthToken) {
          try {
            const getInventoryResponse = await requestInventoryItems()

            setIsInitialized(true)
            // Populate the inventory object with the inventory returned from the rewards api
            setInventory(getInventoryResponse)
          } catch (error) {
            setErrorMessage(getInventoryErrorMessage)
          }
        } else {
          // we redirect them back to the home page to login
          window.location.href = baseUri || 'https://rewards.alpha.pokemon.com'
        }
      }
      handleCallback()
    }
  // eslint-disable-next-line
  }, [])

  return (
    <div>
      <ModalContextProvider>
        <SelectedInventoryItemContext.Provider value={selectedInventoryItem}>
          <InventoryItemModal />
          <div className='body-container'>
            <img className='header-image' src={header} alt="twitch header with pikachu"/>
            <div className='base-container-border main-area h-full' >
              <div>
                <h1 className='mt-2.5 pt-5 text-4xl font-bold'>{inventoryHeaderText}</h1>
                <div className='text-sm'>{trainerUsername}</div>
              </div>
              <div className='grid-container w-5/6 h-full' >
                <div className='flex h-6 mt-2.5'>
                  <div className='relative left-0'>
                    <a className='flex z-0' href={baseUri}><img src={backArrow} alt="back button"/>
                      <div className='mr-2'></div>{t('back', 'Back')}
                    </a>
                  </div>
                  <div className='absolute right-0'>
                    {sortByText}: <b>{mostRecentText}</b>
                  </div>
                </div>
                <div className='error-message'>{errorMessage}</div>
                {(errorMessage === '' && !isInitialized)
                  ? <div className='loading-spinner'>
                      <BeatLoader color='black' />
                      <br/> {loadingMessage}
                    </div>
                  : null
                }
                <div className='flex flex-row flex-wrap gap-5 mt-6'>
                  {(isInitialized && sortedDescendingDateInventory.length === 0)
                    ? <div className='text-center w-full items-center'>
                        <img className='m-auto' src={toolboxIcon} alt='inventory-toolbox'/>
                        <br/>
                        {noItemsMessage}
                      </div>
                    : sortedDescendingDateInventory.map((item, index) => (
                      <InventoryItem
                        key={item.id}
                        inventoryItem={item}
                        setInventoryItem={(item: InventoryItemModel) => { setSelectedInventoryItem(item) }}></InventoryItem>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </SelectedInventoryItemContext.Provider>
      </ModalContextProvider>
    </div>
  )
}
