import { useTranslation } from 'react-i18next'

export const TcglInstructions = () => {
  const { t } = useTranslation()
  return (
    <div className='text-sm'>
      <div className='font-bold'>
        {t('redeem_in_app_title', 'Redeem in the Pokémon TCG Live app (in-game on Android or desktop)')}
      </div>
      <ol className='list-decimal list-outside ml-4'>
        <li>{t('open_app', 'Open the app and navigate to the Shop.')}</li>
        <li>{t('select_redeem', 'Select Redeem.')}</li>
        <li>{t('manually_enter_or_scan', 'Either manually enter the code or use a webcam to scan the card\'s QR code.')}</li>
        <li>{t('check_item', 'Once a code has been submitted and validated, you must check each item and then select Claim Now. Afterward, the item(s) will either be automatically redeemed to your collection, or they will be sent to the Inbox under the Menu. For example, booster packs and coins will be sent to your inbox.')}</li>
     </ol>

      <div className='font-bold mt-5'>
        {t('redeem_on_website', 'Redeem on the official Pokémon website')}
      </div>

      <ol className='list-decimal list-outside ml-4'>
        <li>{t('navigate_to_website', 'Navigate to the official Pokémon TCG Live Code Redemption page,')}<a href="https://redeem.tcg.pokemon.com/" className='font-bold text-link'> {t('navigate_to_here', 'here.')}</a></li>
        <li>{t('login_to_tcg_live', 'Log in to the account associated with Pokémon TCG Live.')}</li>
        <li>{t('enter_codes_and_submit', 'Enter your code(s) and select Submit Code.')}</li>
        <li>{t('your_rewards_appear', 'Your rewards should appear in the menu below.')}</li>
        <li>{t('select_redeem_to_add_to_collection', 'Select Redeem to add the rewards to your collection.')}</li>
      </ol>
    </div>
  )
}
