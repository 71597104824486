import Axios from 'axios'
import { pokemonClientId, pokemonInfoUri, pokemonOauthProviderUri, pokemonRedirectUri, tokenUri } from './setup'

import cryptoBrowserify from 'crypto-browserify'

export function buildPokemonAuthUrl (state: string, challenge: string) {
  return `${pokemonOauthProviderUri}/oauth2/auth?state=${state}&scope=openid+username+offline&response_type=code&client_id=${pokemonClientId}&code_challenge=${challenge}&code_challenge_method=S256&prompt=login&redirect_uri=${pokemonRedirectUri}`
}

export function callPokemonAuth () {
  // Create the pokemon oauth url
  const base64URLEncode = (str: { toString: (arg0: string) => string }) => {
    return str.toString('base64')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '')
  }

  const sha256 = (buffer: any) => {
    return cryptoBrowserify.createHash('sha256').update(buffer).digest()
  }

  const verifier = base64URLEncode(cryptoBrowserify.randomBytes(32))
  const challenge = base64URLEncode(sha256(verifier))

  const state = base64URLEncode(cryptoBrowserify.randomBytes(16))

  window.sessionStorage.setItem('pkce_verifier', verifier)
  window.sessionStorage.setItem('pkce_token_uri', tokenUri)
  window.sessionStorage.setItem('pkce_state', state)
  window.sessionStorage.setItem('pkce_client_id', String(pokemonClientId))
  window.sessionStorage.setItem('pkce_redirect_uri', pokemonRedirectUri)
  const pokemonAuthUrl = buildPokemonAuthUrl(state, challenge)

  window.location.href = pokemonAuthUrl
}

/**
 * This section is for getting player username and expiration date from the token
*/
// NOTE: not currently being used to show any pokemon user data
export const pokemonInfoApi = Axios.create({
  baseURL: pokemonInfoUri,
  timeout: 15000 // millis
})

export const authHeader = {
  headers: {
    Authorization: `Bearer ${window.sessionStorage.getItem('oauth_access_token')}`
  }
}

export interface UserInfoData {
  expirationTimestamp: Number
  username: string
}

export const requestInfoAboutMe = async (oauthToken: string) => {
  const response = await pokemonInfoApi.get('/me', {
    headers: {
      Authorization: `Bearer ${oauthToken}`
    }
  })

  const region = response.data.mailing_address?.country_region_code

  const userData = {
    username: response.data.username,
    expirationTimestamp: response.data.meta.exp,
    region: response.data.mailing_address?.country_region_code
  }

  window.sessionStorage.setItem('pct_username', response.data.username)
  if (response.data.mailing_address) {
    window.sessionStorage.setItem('region', region)
  }
  return userData
}
