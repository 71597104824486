import Axios from 'axios'
import { apiBaseUri } from './setup'
import { InventoryItemModel } from '../models/inventoryItemModel'

export const api = Axios.create({
  baseURL: apiBaseUri,
  timeout: 15000 // millis
})

export const authHeader = {
  headers: {
    Authorization: `Bearer ${window.sessionStorage.getItem('oauth_access_token')}`
  }
}

export const markRewardAsViewed = async (rewardId: string) => {
  const response = await api.put(`/reward/${rewardId}`, {}, {
    ...authHeader
  })
  return response
}

export const requestInventoryItems = async () => {
  const ptcsOauthToken = window.sessionStorage.getItem('oauth_access_token')
  if (ptcsOauthToken && ptcsOauthToken !== '') {
    const response = await api.get('/rewards', {
      headers: {
        Authorization: 'Bearer ' + ptcsOauthToken
      }
    })
    return response.data as InventoryItemModel[]
  }
  throw Error
}

export async function linkTwitchAccountWithPTCS (ptcsOauthToken: string, twitchAuthCode: string) {
  const response = await api.post('/link', {
    code: twitchAuthCode
  },
  {
    headers: {
      Authorization: 'Bearer ' + ptcsOauthToken
    }
  })
  return response
}

export async function getTwitchAccount (ptcsOauthToken: string) {
  const response = await api.get('/twitch-account', {
    headers: {
      Authorization: 'Bearer ' + ptcsOauthToken
    }
  })
  return response
}
