import '../../styles/base.css'
import './modal.css'
import './leaving_site_modal.css'
import wavingPikachu from './../../assets/images/pikachu.svg'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

interface LeavingSiteModalProps {
  intendedUrl: string
  setModal: Function
  modalRef: React.RefObject<HTMLDivElement>
}

function LeavingSiteModal (props: LeavingSiteModalProps) {
  const { t } = useTranslation()
  const eatClick = (e: any) => {
    e.stopPropagation()
  }

  useEffect(() => {
    // force into view
    props.modalRef?.current?.scrollIntoView({ block: 'start' })
    props.modalRef.current?.focus()
  }, [props.modalRef])

  return (
    <div id='leaving-site-modal' className='modal' onClick={eatClick} role="presentation" ref={props.modalRef}>
      <div className='graphic'>
        <img src={wavingPikachu} alt='waving pikachu' />
      </div>
      <div className='content'>
        <div className='big-words'>{t('leaving_pokemon_header', 'You are about to leave a site operated by The Pokémon Company International, Inc.')}</div>
        <div className='small-words'>{t('leaving_pokemon_text', 'The Pokémon Company International is not responsible for the content of any linked website that is not operated by The Pokémon Company International. Please note that these websites\' privacy policies and security practices may differ from The Pokémon Company International\'s standards.')}</div>
        <div className='buttons'>
          <div
            className='button cancel'
            onClick={() => props.setModal(undefined)}
            onKeyPress={() => props.setModal(undefined)}
            role='button'
            tabIndex={0}
          >
            {t('cancel', 'Cancel')}
          </div>
          <a
            role='button'
            className='button'
            href={props.intendedUrl}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => props.setModal(undefined)}
            tabIndex={0}
          >
            {t('continue', 'Continue')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default LeavingSiteModal
