import React, { createContext, useState } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { History } from 'history'
import { useTranslation } from 'react-i18next'
import '@pokemon/design.ui.containers.content-box' // used for the css
import '@pokemon/design.ui.containers.background' // used for the background
import { ErrorFallback } from '@pokemon/design.ui.error.error-fallback'

import './app.css'
import spinner from './assets/images/pokeball_gray.png'
import { AppWrapper } from './template'
import { Header } from './components/header'
import Footer from './components/footer'
import Modal from './components/modals/modal'
import { Landing } from './pages/landing'
import { Callback } from './pages/callback'
import { TwitchCallback } from './pages/twitch_callback'
import { Inventory } from './pages/inventory'

export type UserInfo = {
  loggedIn: boolean
  ptcsUsername: string
  twitchUsername: string
  tokenExpiration: Date
}

type UserInfoContext = [UserInfo, React.Dispatch<React.SetStateAction<UserInfo>>]
const defaultUserInfo = {
  loggedIn: false,
  ptcsUsername: '',
  twitchUsername: '',
  tokenExpiration: new Date()
} as UserInfo

export const UserContext = createContext<UserInfoContext>([defaultUserInfo, () => null])

function App () {
  const { t } = useTranslation()

  const [spinnerVisible, setSpinnerVisible] = useState(false)
  const displayApp = (spinnerVisible) ? 'none' : ''
  const displaySpinner = (spinnerVisible) ? '' : 'none'

  const [modal, setModal] = useState<'Caru' | 'TermsOfUse' | 'RedeemCodeInstructions' | undefined>(undefined)
  const [errorText, setErrorText] = useState<string|undefined>(undefined)
  const setError = (history: History, text: string) => {
    setErrorText(text)
    history.replace('/error')
  }

  const routeProps = {
    setSpinnerVisible,
    setError
  }
  let loggedIn = false
  if (window.sessionStorage.getItem('oauth_access_token')) {
    loggedIn = true
  }

  const defaultUserInfo = {
    loggedIn: loggedIn,
    ptcsUsername: window.sessionStorage.getItem('pct_username'),
    twitchUsername: window.sessionStorage.getItem('twitch_username')
  } as UserInfo
  const [userInfo, setUserInfo] = useState(defaultUserInfo)

  const WrappedErrorFallback = ({ text }: { text?: string }) =>
    <AppWrapper>
      <ErrorFallback
        header={t('pokemon_rewards_program', 'Pokémon Rewards Program')}
        text={text}
        primaryButton={false}
        secondaryButton={false}
      />
    </AppWrapper>

  return (
    <Router>
        <UserContext.Provider value={[userInfo, setUserInfo]}>
          <Header/>
          <div style={{ display: displayApp }}>
            <Modal type={modal} setModal={setModal}/>
              <Switch>
                <Route path='/error'>
                  <WrappedErrorFallback text={errorText} />
                </Route>
                <Route exact path='/' render={(props) => <Landing {...{ ...props, ...routeProps }} />} />
                <Route exact path='/callback' render={(props) => <Callback {...{ ...props, ...routeProps }} />} />
                <Route exact path='/twitch-callback' render={(props) => <TwitchCallback {...{ ...props, ...routeProps }} />} />
                <Route exact path='/inventory' render={(props) => <Inventory {...{ ...props, ...routeProps }} />} />
              </Switch>
          </div>
        </UserContext.Provider>
        <div style={{ display: displaySpinner }}>
          <img className='spinner' src={spinner} alt={t('pokemon_spinner', 'A spinner, used for loading')} />
        </div>
        {/* force footer to bottom */}
        <div style={{ flexGrow: 1 }}></div>
        <Footer setModal={setModal}/>
    </Router>
  )
}

export default App
